import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import {
  ShippingHeroSection,
  ShippingContentSection,
} from '../components/sections';

const Shipping: React.FC = () => (
  <Layout>
    <SEO
      title="Shipping"
      titleTemplate=""
      meta={[
        {
          name: 'keywords',
          content: 'shipping',
        },
      ]}
    />
    <ShippingHeroSection />
    <ShippingContentSection />
  </Layout>
);

export default Shipping;
